import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueNativeSock from "vue-native-websocket";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/nprogress/nprogress.css";

import "./registerServiceWorker";
import { EventBus } from "./services/event-bus";

// ADDING GLOBAL PROPERTIES
Vue.prototype.$eventBus = EventBus;
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.prototype.$localStorage = new Vue({
  data: {
    // token property returning the ls token value
    token: window.localStorage.getItem("loggedinUserToken"),
    fullName: window.localStorage.getItem("loggedinUserName"),
    showWarnig: false,
    // requiresAuth:true,
  },
  watch: {
    // watcher listening for changes on the token property
    // to ensure the new value is written back to ls
    token(value) {
      window.localStorage.setItem("loggedinUserToken", value);
    },
    fullName(value) {
      window.localStorage.setItem("loggedinUserName", value);
    },
  },
  created() {
    this.connect();
  },
  methods: {
    connect() {
      const self = this;
      this.connection = new WebSocket(process.env.VUE_APP_WS_URL);
      this.connection.onopen = function (event) {
        console.log("Socket Connected");
      };

      this.connection.onmessage = function (event) {
        let dataObj = JSON.parse(event.data);
        self.$eventBus.$emit("socket-event-header", dataObj);
        self.$eventBus.$emit("socket-event", dataObj);
      };

      this.connection.onerror = function (e) {
        console.log("Socket Error");
        self.connection.onclose();
      };
      this.connection.onclose = function (e) {
        console.log("Socket Connection Close");
        setTimeout(function () {
          self.connect();
        }, 1000);
      };
    }
  }
});

Vue.use(VueNativeSock, process.env.VUE_APP_WS_URL, {
  store: store,
  format: "json",
  // mutations: mutations,
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 1000, // (Number) how long to initially wait before attempting a new (1000)
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");