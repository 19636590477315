<template>
  <div>
    <!-- SIGN UP Modal -->
    <!-- SIGN IN Modal -->
    <div
      class="modal fade"
      id="safelogin"
      tabindex="-1"
      role="dialog"
      aria-labelledby="safelogin"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body loginbox">
            <button
              v-on:click="closeModal()"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <a href="/" class="logo"
              ><img src="../assets/images/logo-small.png" alt="Safe-waters"
            /></a>

            <h1>SIGN IN</h1>
            <p>Log in to access your personal account</p>
            <form @submit.prevent="handleSubmit" class="signin-form">
              <input
                v-model="loginemail"
                type="email"
                id="loginemail"
                placeholder="Email"
                :class="{ 'is-invalid': submitted && !loginemail }"
              />

              <div v-show="submitted && !loginemail" class="invalid-feedback">
                Email is required
              </div>
              <div class="password">
                <input
                  v-model="passwordlogin"
                  v-bind:type="[showPasswordlogin ? 'text' : 'password']"
                  id="passwordlogin"
                  placeholder="Password"
                  :class="{ 'is-invalid': submitted && !passwordlogin }"
                />

                <span @click="showPasswordlogin = !showPasswordlogin">
                  <i
                    class="fa"
                    :class="[showPasswordlogin ? 'fa-eye' : 'fa-eye-slash']"
                    aria-hidden="true"
                  ></i>
                </span>
                <div
                  v-show="submitted && !passwordlogin"
                  class="invalid-feedback"
                >
                  Password is required
                </div>
              </div>
              <a href="javascript:void(0);" v-on:click="showRecoverPwdModal()"
                >Recovery password</a
              >
              <!--  <input type="submit" name="" value="LOG IN"> -->
              <input type="submit" value="LOG IN" />
            </form>
            <a
              href="javascript:void(0);"
              v-on:click="showRecoverPwdModal()"
              class="forgotpass"
              >Forgot password?</a
            >
            <div class="registertext">
              If you don’t have an account. You can
              <a href="javascript:void(0);" v-on:click="showRegmodal()"
                >Register here!</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SIGN UP Modal -->

    <div
      class="modal fade"
      id="safesignup"
      tabindex="-1"
      role="dialog"
      aria-labelledby="safesignup"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body loginbox">
            <button
              type="button"
              v-on:click="closeModal()"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <a href="/" class="logo"
              ><img src="../assets/images/logo-small.png" alt="Safe-waters"
            /></a>

            <h1>SIGN UP</h1>
            <p>
              Register to receive important updates from selected facilities.
            </p>
            <form @submit.prevent="handleSubmitReg" class="signin-form">
              <input
                type="text"
                v-model="name"
                placeholder="Full Name"
                :class="{ 'is-invalid': submitted && !name }"
                maxlength="50"
              />
              <div v-show="submitted && !name" class="invalid-feedback">
                Full Name is required
              </div>

              <input
                type="text"
                v-model="email"
                placeholder="Email"
                :class="{ 'is-invalid': submitted && !email }"
              />

              <div v-show="submitted && !email" class="invalid-feedback">
                Email is required
              </div>

              <div class="password">
                <input
                  v-bind:type="[showPassword ? 'text' : 'password']"
                  v-model="password"
                  placeholder="password"
                  :class="{ 'is-invalid': submitted && !password }"
                />

                <span @click="showPassword = !showPassword">
                  <i
                    class="fa"
                    :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']"
                    aria-hidden="true"
                  ></i>
                </span>

                <div v-show="submitted && !password" class="invalid-feedback">
                  Password is required
                </div>
              </div>
              <label v-if="fullPage"></label>
              <input type="submit" value="Create acount" />
            </form>
            <div class="registertext">
              Already have an account?
              <a href="javascript:void(0);" v-on:click="showLoginmodal()"
                >Sign in</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Forgot password Modal -->
    <div
      class="modal fade"
      id="safeforgotpass"
      tabindex="-1"
      role="dialog"
      aria-labelledby="safeforgotpass"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body loginbox">
            <button
              v-on:click="closeModal()"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <a href="/" class="logo"
              ><img src="../assets/images/logo-small.png" alt="Safe-waters"
            /></a>
            <h1>Forgot password</h1>
            <p>
              If you are not password, go thought the Forgot password procedure
            </p>

            <form @submit.prevent="forgotPassword" class="signin-form">
              <input
                type="email"
                v-model="email"
                placeholder="Email"
                :class="{ 'is-invalid': submitted && !email }"
              />
              <div v-show="submitted && !email" class="invalid-feedback">
                Email is required
              </div>
              <input type="submit" value="Forgot password" />
            </form>
            <div class="registertext">
              Already have an account?
              <a href="javascript:void(0);" v-on:click="showLoginmodal()"
                >Sign in</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Reset password Modal -->
    <div
      class="modal fade"
      id="saferesetpassword"
      tabindex="-1"
      role="dialog"
      aria-labelledby="saferesetpassword"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body loginbox">
            <button
              v-on:click="closeModal()"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <a href="/" class="logo"
              ><img src="../assets/images/logo-small.png" alt="Safe-waters"
            /></a>
            <h1>Reset Password</h1>
            <p>
              If you are not password, go thought the Forgot password procedure
            </p>
            <form @submit.prevent="resetPassword" class="signin-form">
              <div class="password">
                <input
                  type="password"
                  placeholder="Password"
                  v-model="user.password"
                  id="password"
                  name="password"
                  :class="{
                    'is-invalid': submitted && $v.user.password.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.user.password.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.user.password.required"
                    >Password is required</span
                  >
                  <span v-if="!$v.user.password.minLength"
                    >Password must be at least 8 characters</span
                  >
                </div>

                <input
                  type="password"
                  placeholder="Confirm Password"
                  v-model="user.confirmPassword"
                  id="confirmPassword"
                  name="confirmPassword"
                  :class="{
                    'is-invalid': submitted && $v.user.confirmPassword.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.user.confirmPassword.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.user.confirmPassword.required"
                    >Confirm Password is required</span
                  >
                  <span v-else-if="!$v.user.confirmPassword.sameAsPassword"
                    >Passwords must match</span
                  >
                </div>
              </div>

              <input type="submit" value="Reset Password" />
            </form>
            <div class="registertext">
              Already have an account?<a
                href="javascript:void(0);"
                v-on:click="showLoginmodal()"
                >Sign in</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import ToasterService from "../services/toasterService";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import customInstance from "../config";
const toastMessage = new ToasterService();

Vue.use(Loading);
export default {
  data() {
    return {
      email: "",
      password: "",
      name: "",
      submitted: false,
      errors: [],
      message: "",
      showPassword: false,
      showPasswordlogin: false,
      loginemail: "",
      passwordlogin: "",
      password_length: 0,
      typed: false,
      contains_lovercase: false,
      contains_number: false,
      contains_uppercase: false,
      valid_password_length: false,
      valid_password: false,
      fullPage: false,
      user: {
        password: "",
        confirmPassword: "",
      },
    };
  },
  validations: {
    user: {
      password: { required, minLength: minLength(8) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
  },
  created() {
    if (this.$route.query.token != undefined) {
      this.verifyToken();
    }
  },
  methods: {
    closeModal() {
      $(this).find("form").trigger("reset");
      this.submitted = false;
      this.loginemail = "";
      this.passwordlogin = "";
      this.messagelogin = "";
    },
    showRegmodal() {
      this.submitted = false;
      this.email = "";
      this.name = "";
      this.password = "";

      $("#safelogin").modal("hide");
      $("#safeforgotpass").modal("hide");
      $("#saferesetpassword").modal("hide");

      $("#safesignup")
        .modal({
          backdrop: "static",
          keyboard: false,
        })
        .modal("show");

      setTimeout(() => {
        if (!$("body").hasClass("modal-open")) {
          $("body").addClass("modal-open");
        }
      }, 500);
    },
    showLoginmodal() {
      this.submitted = false;
      $("#safesignup").modal("hide");
      $("#safeforgotpass").modal("hide");
      $("#saferesetpassword").modal("hide");

      $("#safelogin")
        .modal({
          backdrop: "static",
          keyboard: false,
        })
        .modal("show");

      setTimeout(() => {
        if (!$("body").hasClass("modal-open")) {
          $("body").addClass("modal-open");
        }
      }, 500);
    },

    showRecoverPwdModal() {
      this.submitted = false;
      this.email = "";
      $("#safesignup").modal("hide");
      $("#safelogin").modal("hide");
      $("#saferesetpassword").modal("hide");

      $("#safeforgotpass")
        .modal({
          backdrop: "static",
          keyboard: false,
        })
        .modal("show");

      setTimeout(() => {
        if (!$("body").hasClass("modal-open")) {
          $("body").addClass("modal-open");
        }
      }, 500);
    },

    handleSubmit(e) {
      this.submitted = true;
      const { loginemail, passwordlogin } = this;
      if (loginemail && passwordlogin) {
        let loader = Vue.$loading.show();
        customInstance
          .post("/signIn", {
            email: this.loginemail,
            password: this.passwordlogin,
            deviceToken: "xwdd",
          })
          .then((response) => {
            loader.hide();
            if (response.data.status == 200) {
              if (response.data.role == "U") {
                this.loginemail = "";
                this.passwordlogin = "";

                this.submitted = false;

                localStorage.setItem("loggedinUserToken", response.data.data);
                localStorage.setItem(
                  "loggedinUserName",
                  response.data.userName
                );
                const token = response.data.data;
                const loggedinUserName = response.data.userName;
                this.$localStorage.token = token;
                this.$localStorage.fullName = loggedinUserName;

                $("#safelogin").modal("hide");
                toastMessage.success("Login successful");
                if (this.$route.name == "facility") {
                  this.$router.go();
                } else {
                  this.$router.push({ path: "/favorites" });
                }
              } else {
                this.loginemail = "";
                this.passwordlogin = "";
                toastMessage.error("something went wrong please try again");
                this.submitted = false;
              }
            }
            if (response.data.status == 400) {
              toastMessage.error(response.data.data);
              this.passwordlogin = "";
              this.submitted = false;
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    login: function (email, pwd) {
      this.submitted = true;
      customInstance
        .post("/signIn", {
          email: email,
          password: pwd,
          deviceToken: "xwdd",
        })
        .then((response) => {
          if (response.data.status == 200) {
            if (response.data.role == "U") {
              this.submitted = false;
              localStorage.setItem("loggedinUserToken", response.data.data);
              localStorage.setItem("loggedinUserName", response.data.userName);
              const token = response.data.data;
              const loggedinUserName = response.data.userName;
              this.$localStorage.token = token;
              this.$localStorage.fullName = loggedinUserName;
              this.$router.push({ path: "/favorites" });
              $("#safesignup").modal("hide");
            } else {
              toastMessage.error("something went wrong please try again");
              this.submitted = false;
            }
          }
          if (response.data.status == 400) {
            toastMessage.error(response.data.data);

            this.submitted = false;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    handleSubmitReg(e) {
      this.submitted = true;
      const { name, email, password } = this;

      if (name && email && password) {
        if (!this.isEmailValid(email)) {
          toastMessage.error("Email is invalid!");
          return false;
        }

        if (password.length <= 8) {
          toastMessage.error("Password must be more than 8 characters!");
          return false;
        }

        let loader = Vue.$loading.show();
        customInstance
          .post("/frontendSignUp", {
            body: {
              email: this.email,
              password: this.password,
              name: this.name,
              role: "U",
            },
          })
          .then((response) => {
            loader.hide();
            if (response.data.status == 200) {
              this.submitted = false;
              toastMessage.success("You have successful registered and login");
              this.login(this.email, this.password);
            }
            if (response.data.status == 400) {
              toastMessage.error(response.data.data);
            }
          })
          .catch((e) => {
            loader.hide();
            this.errors.push(e);
          });
      }
    },
    forgotPassword(e) {
      this.submitted = true;
      const { email } = this;

      if (email) {
        let loader = Vue.$loading.show();
        customInstance
          .post("/forgetPassword", {
            email: email,
            deviceToken: "xwdd",
            siteUrl: window.location.origin,
            loginUrl: window.location.origin,
          })
          .then((response) => {
            loader.hide();
            if (response.data.status == 200) {
              this.submitted = false;
              toastMessage.success(
                "Password reset link has been sent to your email"
              );
              this.email = "";
              this.showLoginmodal();
            }
            if (response.data.status == 400) {
              toastMessage.error(response.data.data);
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    verifyToken: function () {
      customInstance
        .post("/forgetTokenVerify", {
          token: this.$route.query.token,
        })
        .then((response) => {
          if (response.data.status == 200) {
            if (this.$route.query.token != undefined) {
              setTimeout(
                () =>
                  $("#saferesetpassword").modal({
                    backdrop: "static",
                    keyboard: false,
                  }),
                100
              );
            }
          }
          if (response.data.status == 400) {
            toastMessage.error(response.data.data);
          }
          loader.hide();
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    resetPassword(e) {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      let loader = Vue.$loading.show();
      customInstance
        .post("/passwordReset", {
          password: this.user.password,
          token: this.$route.query.token,
        })
        .then((response) => {
          loader.hide();
          if (response.data.status == 200) {
            $("#saferesetpassword").modal("hide");
            toastMessage.success("Password changed successfully");
            $("#safelogin").modal("show");
            this.submitted = false;
          }
          if (response.data.status == 400) {
            toastMessage.error(response.data.data);
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    isEmailValid(email) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
    },
  },
};
</script>
<style type="text/css">
.success {
  color: green;
  text-align: center;
  font-size: 19px;
  font-weight: bold;
}
.signErr {
  color: red;
  text-align: center;
  font-size: 19px;
  font-weight: bold;
}
</style>